/* eslint-disable react/display-name */

import {ReactNode, FC, PropsWithChildren} from "react"
import {Text} from "@nextui-org/react"
import Nav from "./Nav"

const Header: FC = () => (
  <div className="flex items-center space-x-8">
    <Text h1 color="secondary">
      Debrief.
    </Text>
    <Nav />
  </div>
)

interface Props {
  children: ReactNode
  withHeader?: boolean
}

const Layout: FC<Props> = ({children, withHeader}) => (
  <div className="px-4 flex flex-col">
    {withHeader && <Header />}
    {children}
  </div>
)

// prettier-ignore
const withLayout = <P,>(Component: FC<P>, layoutProps?: Omit<Props, "children">) => (props: P) => {
  return (
    <Layout {...layoutProps}>
      <Component {...props} />
    </Layout>
  )
}

export default Layout
export {withLayout}
